
.main {
    /* background-color:#073E60; */
    height: 100%;
  }
  
  .title{
    color:white;
    font-size: 50px;
    text-align: center;
    border-bottom: 4px solid white;
  }
  
  .judges-title{
    color:white;
    font-size: 50px;
    text-align: center;
    border-top: 4px solid white;
    border-bottom: 4px solid white;
  }
  
  .table_ranking{
    color:white;
    font-size: 35px;
  }
  
  .points{
    text-align: center;
  }
  
  .name{
    text-align: left;
    padding-left:0px !important;
    padding-right:0px !important;
    font-size: 25px;
    vertical-align: middle;
    padding-top:8px;
    max-height: 50px;
  }
  
  .rank{
    text-align: center;
    padding-right:0px !important;
    padding-left:0px !important;
  }
  
  .bigcoloneMiddle{
    border-right: 1px solid white;
  }
  
  .cont{
    padding:5 !important;
  }
  
  .line{
    padding-top:10px;
    padding-bottom:10px;
    border-bottom: 1px solid white;
    max-height:90px;
  }
  .imgContainer{
    background-color: white;
  }
  .lineYellow{
    background-color: white;
    width:100%;
    height:4px;
    margin-top: 30px;
  }
  .leaders{
  }
  
  .time{
    color:white;
    font-size: 100px;
    text-align: center;
    font-variant-numeric: tabular-nums;
    border-bottom: 4px solid white;
  }
  
  .chrono{
    font-variant-numeric: tabular-nums;
  }
  
  .data_waiting{
    color:white;
    padding-top:10px;
    font-size: 40px;
    text-align: center;
  }
  
  .time-table{
    font-size: 40px;
    vertical-align: middle;
    text-align: center;
    font-variant-numeric: tabular-nums;
  
  }

  .sub-score{
      padding-top:15px;
      font-size:40px;
  }
  
  .alignCenter{
    text-align: center;
  }
  .alignRight{
    text-align: right;
  }
  
  .table-results{
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  
  .line-res{
    font-size: 30px;
    border-bottom: 1px solid white;
  }
  .line-res-cont-rank{
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
  }
  .line-res-rank{
    font-weight: bold;
    
  }
  .line-res-horse{
    font-size: 40px;
  }
  .line-res-res{
    font-size: 24px;
    font-weight: bold;
  }
  .line-res-cav-horse{
    padding-left: 2 !important;
    padding-right: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .table-last{
    background-color: #031f30;
    margin-top:unset;
  }
  .last-title{
    border-bottom: 2px solid white;
  }
  .last-line-res{
    font-size: 50px;
  }
  .stats-title{
    border-bottom: 1px solid white;
    font-size: 25px;
    text-align: center;
  }
  .stats-core{
    text-align: center;
  }
  .stats-core-small{
    font-size: 16px;
  }
  .stats-core-padd{
    padding-top:12px;
  }
  .judges{
    font-size: 20px;
    font-weight: normal;
  }