/* .main{
  background-color:black;
} */
.mini_full-rider{
  width:600px;
  color:#ffffff;
  font-size:60px;
  font-weight: bold;
  position:absolute;
  top:0px;
  left:150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
.mini_full-horse{
  color:#ffffff;
  width:743px;
  font-size:60px;
  font-weight: bold;
  position:absolute;
  top:63px;
  left:15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mini_full-chrono{
  width:300px;
  color:#ffffff;
  font-size:80px;
  font-weight: bold;
  position:absolute;
  top:140px;
  left:15px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.mini_full-points{
  color:#ffffff;
  width:200px;
  font-size:80px;
  font-weight: bold;
  position:absolute;
  top:140px;
  left:568px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.mini_full-rank{
  color:#ffffff;
  width:278px;
  font-size:50px;
  font-weight: bold;
  font-style: italic;
  position:absolute;
  top:190px;
  left:320px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.mini_full-ecart{
  color:#ffffff;
  font-size:30px;
  position:absolute;
  top:155px;
  left:310px;
  width:200px;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.mini_full-flag{
  position:absolute;
  top:15px;
  left:15px;
  width:120px;
}

.mini_line{
  background-color: white;
  width:700px;
  height:2px;
  top:150px;
  left:30px;
  position:absolute;
  display: none;
}

.mini_time{
  color:#ffffff;
  width:768px;
  font-size:130px;
  font-weight: bold;
  position:absolute;
  top:25px;
  left:0px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.mini2-ecartlive-lines{
  width:256px;
  height:187px;
  color:#ffffff;
  position:absolute;
  top:330px;
  left:0px;
  /* background-color: black; */
  border-top: 5px black solid;
}

.mini2-ecartlive{
  width:256px;
  height:59px;
  color:#ffffff;
  position:relative;
  /* margin-top: 5px; */
  background-repeat: no-repeat;
  background-attachment: fixed; 
  background-size: 100% 100%;  
  border-bottom: 5px black solid;
  /* border-top: 2px black solid; */
  /* background-color: red; */
}
.mini2-ecartlive-text{
  width:180px;
  color:#000000;
  font-size:40px;
  position:absolute;
  top:-3px;
  left:72px;
  line-height: 59px;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  /* background-color: yellow; */
}
.mini2-ecartlive-title{
  width:75px;
  color:#ffffff;
  font-size:40px;
  position:absolute;
  top:-3px;
  left:0px;
  line-height: 59px;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  /* background-color: yellow; */
}