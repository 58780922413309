.giant-presentation{
  position:absolute;
  top:780px;
  left:28px;
  width:1863px;
  height:274px;

}
.giant-rider{
  width:1400px;
  color:#ffffff;
  font-size:100px;
  font-weight: bold;
  position:absolute;
  top:-10px;
  left:50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.giant-horse{
  color:#ffffff;
  width:1500px;
  font-size:100px;
  font-weight: bold;
  position:absolute;
  top: 138px;
  left:250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.giant-chrono{
  width:573px;
  height:129px;
  color:#ffffff;
  position:absolute;
  top:20px;
  left:1325px;
}
.giant-chrono-text{
  width:573px;
  color:#ffffff;
  font-size:120px;
  font-weight: bold;
  position:absolute;
  top:-25px;
  left:10px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.giant-points{
  width:229px;
  height:129px;
  color:#ffffff;
  font-size:275px;
  font-weight: bold;
  position:absolute;
  top:160px;
  left:1670px;
}

.giant-points-text{
  width:229px;
  color:#ffffff;
  font-size:120px;
  font-weight: bold;
  position:absolute;
  top:-25px;
  left:20px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.giant-rank{
  color:#ffffff;
  width:593px;
  height:129px;
  position:absolute;
  top:20px;
  left:28px;
  text-align: center;
}
.giant-rank-text{
  color:#ffffff;
  width:593px;
  font-size:100px;
  font-weight: bold;
  position:absolute;
  top: -10px;
  left: 0px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.giant-ecartlive-lines{
  width:573px;
  color:#ffffff;
  position:absolute;
  top:300px;
  left:1500px;
}

.giant-ecartlive{
  width:397px;
  height:110px;
  color:#ffffff;
  position:relative;
  margin-bottom: 10px;
}
.giant-ecartlive-text{
  width:404px;
  color:#000000;
  font-size:80px;
  position:absolute;
  top:3px;
  left:45px;
  line-height: 110px;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  
}
.giant-ecartlive-title{
  width:160px;
  color:#ffffff;
  font-size:100px;
  position:absolute;
  top:0px;
  left:-25px;
  line-height: 110px;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  /* background-color: yellow; */
}


.giant-ecart{
  width:404px;
  height:129px;
  color:#ffffff;
  position:absolute;
  top:160px;
  left:1325px;

}
.giant-ecart-text{
  width:404px;
  color:#ffffff;
  font-size:100px;
  position:absolute;
  top:-10px;
  left:-20px;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.giant-ranking-title{
  position:absolute;
  top:10px;
  left:28px;
  width:1860px;
  height:107px;
}
.giant-ranking-title-text{
  width:1825px;
  color:#ffffff;
  font-size:100px;
  font-weight: bold;
  position:absolute;
  top:-20px;
  left:10px;
  text-align:center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.giant-flag{
  left:1600px;
  top:16px;
  position:absolute;
}
.giant-drapeau{
  width:250px;
}

.giant-ranking-flag{
  position:absolute;
  top:18px;
  left:1250px;
  text-align:center;
}
.giant-ranking-drapeau{
  width:150px;
}

.giant-ranking-lines{
  position:absolute;
  top:150px;
  left:28px;
  width:1860px;
  height:1000px;
}
.giant-ranking-line{
  width:1860px;
  height:166px;
  position:relative;
  margin-bottom:20px;
}
.giant-ranking-line-rank{
  color:#ffffff;
  position:absolute;
  font-size:75px;
  font-weight: bold;
  top:-10px;
  left:0px;
  text-align:center;
  width:120px;
}
.giant-ranking-line-rider{
  color:#ffffff;
  position:absolute;
  font-size:80px;
  font-weight: bold;
  top:-15px;
  left:140px;
  text-align:left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:1100px;

}
.giant-ranking-line-horse{
  color:#ffffff;
  position:absolute;
  font-size:70px;
  font-weight: bold;
  top:77px;
  left:400px;
  text-align:center;
}
.giant-ranking-line-res{
  color:#ffffff;
  position:absolute;
  font-size:70px;
  font-weight: bold;
  top:-5px;
  left:1450px;
  text-align:center;
  width:400px;
}
.giant-gn{
  color:#ffffff;
  position:absolute;
  font-size:100px;
  font-weight: bold;

  width:1920px;
  height:1080px;
  top:0px;
  left:0px;
}
.giant-gn-text{
  top:285px;
  left:50px;
  position:absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:1850px;
}
.giant-gn-logo{
  top:0px;
  position:absolute;
}
.giant-countdown{
  width:400px;
  color:white;
  font-size:150px;
  font-weight: bold;
  position:absolute;
  top:0px;
  left:0px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* background-color: orange; */
}

.giant-team-live{
  position:absolute;
  top:0px;
  left:0px;
  width:300px;
  height:700px;
  /* background-color: red; */
}
.giant-team-live-line{
  width:300px;
  height:70px;
  position:relative;
  margin-bottom:0px;
}
.giant-team-live-rank{
  color:#ffffff;
  position:absolute;
  font-size:60px;
  font-weight: bold;
  top:-10px;
  left:-15px;
  text-align:center;
  width:120px;
}
.giant-team-live-nation{
  left:95px;
  top:12px;
  position:absolute;
  width:120px;
}
.giant-team-live-flag{
  width:120px;
}
.giant-team-live-points{
  color:#ffffff;
  position:absolute;
  font-size:60px;
  font-weight: bold;
  top:-10px;
  left:200px;
  text-align:center;
  width:120px;
}
.giant-team-live-line-even{
  background-color: black;
}
.giant-team-live-line-odd{
  background-color: rgb(58, 58, 58);
}

.giant-team-live-line-selected{
  background-color: #F36C20;
}
