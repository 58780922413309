@font-face {
  font-family: "AlbertSans";
  font-weight: bold;
  src: url("fonts/AlbertSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "AlbertSans";
  font-weight: normal;
  src: url("fonts/AlbertSans-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "AlbertSans";
  font-weight: bold;
  font-style: italic;
  src: url("fonts/AlbertSans-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "AlbertSansTiming";
  font-weight: bold;
  src: url("fonts/AlbertSans-Bold-Timing.otf") format("opentype");
}
.tv_presentation{
  position:absolute;
}

.tv_rider{
  width:450px;
  color:#ffffff;
  font-size:30px;
  font-weight: bold;
  font-family: AlbertSans;
  position:absolute;
  top:896px;
  left:280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tv_fistname{
  font-weight: normal;
}

.tv_horse{
  color:#ffffff;
  width:500px;
  font-size:25px;
  font-weight: bold;
  font-family: AlbertSans;
  position:absolute;
  top:948px;
  left:280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tv_flag{
  position:absolute;
  top:903px;
  left:730px;
  width:40px;
}

.tv_nation{
  width:70px;
  color:#ffffff;
  font-size:25px;
  font-weight: bold;
  font-family: AlbertSans;
  position:absolute;
  top:900px;
  left:770px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tv_time{
  width:140px;
  color:#ffffff;
  font-size:30px;
  font-weight: bold;
  font-family: AlbertSansTiming;
  position:absolute;
  top:944px;
  left:1503px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tv_pen{
  width:90px;
  color:#0D3B63;
  font-size:30px;
  font-weight: bold;
  font-family: AlbertSans;
  position:absolute;
  top:944px;
  left:1664px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tv_timeback{
  position:absolute;
}
.tv_penback{
  position:absolute;
}

.tv_riderlive{
  width:300px;
  color:#ffffff;
  font-size:26px;
  font-weight: bold;
  font-family: AlbertSans;
  position:absolute;
  top:947px;
  left:180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tv_horselive{
  color:#ffffff;
  width:380px;
  font-size:23px;
  font-weight: bold;
  font-family: AlbertSans;
  position:absolute;
  top:992px;
  left:220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tv_flaglive{
  position:absolute;
  top:952px;
  left:490px;
  width:40px;
}

.tv_nationlive{
  width:70px;
  color:#ffffff;
  font-size:25px;
  font-weight: bold;
  font-family: AlbertSans;
  position:absolute;
  top:948px;
  left:528px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tv_pointsback{
  position: absolute;
}

.tv_points{
  width:350px;
  color:#0D3B63;
  /* background-color: red; */
  font-size:20px;
  text-align: center;
  font-weight: bold;
  font-family: AlbertSans;
  position:absolute;
  top:862px;
  left:405px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tv_ecartback{
  position: absolute;
  /* background-color: red; */
  top:0;
}

.tv_ecart{
  width:100px;
  color:#0D3B63;
  /* background-color: red; */
  font-size:30px;
  text-align: center;
  font-weight: bold;
  font-family: AlbertSans;
  position:absolute;
  top:895px;
  left:1544px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tv_ranking-title{
  width:800px;
  color:#ffffff;
  /* background-color: yellow; */
  font-size:30px;
  text-align: center;
  font-weight: bold;
  font-family: AlbertSans;
  position:absolute;
  top:174px;
  left:600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tv_ranking-title2{
  width:200px;
  color:#0D3B63;
  /* background-color: red; */
  font-size:30px;
  text-align: center;
  font-weight: bold;
  font-style: italic;
  font-family: AlbertSans;
  position:absolute;
  top:222px;
  left:600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tv_ranking-lines{
  position:absolute;
  /* background-color: red; */
  top:290px;
  left:28px;
  width:1860px;
  height:120px;
}
.tv_ranking-line{
  /* background-color: red; */
  position: relative;
  top:0
}
.tv_ranking-line-rank{
  width:50px;
  color:#0D3B63;
  /* background-color: red; */
  font-size:30px;
  text-align: center;
  font-weight: bold;
  font-family: AlbertSans;
  position: absolute;
  top:5px;
  left:475px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tv_ranking-line-rider{
  width:500px;
  color:#ffffff;
  /* background-color: red; */
  font-size:28px;
  text-align: left;
  font-weight: bold;
  font-family: AlbertSans;
  position: absolute;
  top:7px;
  left: 550px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tv_ranking-line-horse{
  width:500px;
  color:#ffffff;
  /* background-color: red; */
  font-size:26px;
  text-align: left;
  font-weight: bold;
  font-family: AlbertSans;
  position: absolute;
  top:52px;
  left: 580px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tv_ranking-flag{
  position:absolute;
  top:10px;
  left:1055px;
  width :35px;
}

.tv_ranking-line-nation{
  width:80px;
  color:#ffffff;
  /* background-color: red; */
  font-size:26px;
  text-align: left;
  font-weight: bold;
  font-family: AlbertSans;
  position: absolute;
  text-align: center;
  top:8px;
  left: 1090px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tv_ranking-line-pen{
  width:70px;
  color:#0D3B63;
  /* background-color: yellow; */
  font-size:26px;
  text-align: center;
  font-weight: bold;
  font-family: AlbertSans;
  position: absolute;
  text-align: center;
  top:8px;
  left: 1227px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tv_ranking-line-time{
  width:110px;
  color:#ffffff;
  /* background-color: yellow; */
  font-size:26px;
  text-align: center;
  font-weight: bold;
  font-family: AlbertSans;
  position: absolute;
  text-align: center;
  top:8px;
  left: 1325px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tv_next-title{
  width:600px;
  color:#0D3B63;
  /* background-color: red; */
  font-size:40px;
  text-align: center;
  font-weight: bold;
  font-family: AlbertSans;
  position:absolute;
  text-overflow: ellipsis;
  top:450px;
  left:960px;
}

.tv_next-title2{
  width:550px;
  color:#0D3B63;
  /* background-color: red; */
  font-size:40px;
  text-align: center;
  font-weight: bold;
  font-family: AlbertSans;
  position:absolute;
  top:600px;
  left:1000px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}