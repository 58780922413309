.giant_full_ranking-rider{
  width:1500px;
  color:#ffffff;
  font-size:100px;
  font-weight: bold;
  position:absolute;
  top:10px;
  left:50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.giant_full_ranking-horse{
  color:#ffffff;
  width:1500px;
  font-size:100px;
  font-weight: bold;
  position:absolute;
  top:155px;
  left:50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.giant_full_ranking-flag{
  position:absolute;
  top:45px;
  left:1610px;
  width:200px;
}
.giant_full_ranking-flag-drapeau{
  width:250px;
}

.giant_full_ranking-chrono{
  width:1000px;
  color:#ffffff;
  font-size:230px;
  font-weight: bold;
  position:absolute;
  top:230px;
  left:90px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.giant_full_ranking-points{
  color:#ffffff;
  font-size:230px;
  font-weight: bold;
  position:absolute;
  top:230px;
  left:1300px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:500px;
}

.giant_full_ranking-rank{
  color:#ffffff;
  width:485px;
  height:129px;
  position:absolute;
  top:530px;
  left:0px;
  text-align: center;
}
.giant_full_ranking-rank-text{
  color:#ffffff;
  width:593px;
  font-size:100px;
  font-weight: bold;
  position:absolute;
  top: -10px;
  left: -70px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.giant_full_ranking-extrapoints{
  color:#ffffff;
  width:600px;
  height:129px;
  position:absolute;
  top:530px;
  left:680px;
  text-align: center;

  font-size:100px;
  font-weight: bold;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.giant_full_ranking-ecart{
  width:404px;
  height:129px;
  color:#ffffff;
  position:absolute;
  top:530px;
  left:1515px;

}
.giant_full_ranking-ecart-text{
  width:404px;
  color:#ffffff;
  font-size:100px;
  position:absolute;
  top:-10px;
  left:10px;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;

}

.giant_full_ranking-hu-mini-ranking-lines{
  position:absolute;
  top:405px;
  left:28px;
  width:1860px;
  height:390px;
}
.giant_full_ranking-mini-ranking-lines{
  position:absolute;
  top:660px;
  left:28px;
  width:1860px;
  height:390px;
}
.giant_full_ranking-mini-ranking-line{
  width:1860px;
  height:130px;
  position:relative;
  margin-bottom:0px;
  font-size:80px;
  /* background-color:yellow; */
}
.giant_full_ranking-mini-ranking-line-rank{
  color:#ffffff;
  position:absolute;
  font-weight: bold;
  top:0px;
  left:0px;
  text-align:center;
  width:120px;
}
.giant_full_ranking-mini-ranking-line-rider{
  color:#ffffff;
  width:1190px;
  position:absolute;
  font-weight: bold;
  top:0px;
  left:130px;
  text-align:left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.giant_full_ranking-mini-ranking-line-rider-short{
  display: none;
}

.giant_full_ranking-mini-ranking-line-res{
  width:420px;
  color:#ffffff;
  position:absolute;
  font-size:70px;
  font-weight: bold;
  top:0px;
  left:1470px;
  text-align:center;
}

.giant_full_ranking-mini-ranking-flag{
  position:absolute;
  top:-15px;
  left:1310px;
  width:200px;
}
.giant_full_ranking-mini-ranking-flag-drapeau{
  width:150px;
}

.giant_full-ranking-title{
  position:absolute;
  top:35px;
  left:50px;
  width:1860px;
  height:107px;
}
.giant_full-ranking-title-text{
  width:1860px;
  color:#ffffff;
  font-size:100px;
  font-weight: bold;
  position:absolute;
  top:-15px;
  left:0px;
  text-align:center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.giant_full_ranking-pere {
  color:#ffffff;
  font-style:italic;
  width:1700px;
  font-size:90px;
  font-weight: normal;
  position:absolute;
  top:700px;
  left:100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* background-color: red; */
}

.giant_full_ranking-mere {
  color:#ffffff;
  font-style:italic;
  width:1700px;
  font-size:90px;
  font-weight: normal;
  position:absolute;
  top:875px;
  left:100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
