.mini_full-rider{
  width:600px;
  color:#ffffff;
  font-size:50px;
  font-weight: bold;
  position:absolute;
  top:0px;
  left:150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
.mini_full-horse{
  color:#ffffff;
  width:743px;
  font-size:50px;
  font-weight: bold;
  position:absolute;
  top:63px;
  left:15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mini_full-chrono{
  width:300px;
  color:#ffffff;
  font-size:80px;
  font-weight: bold;
  position:absolute;
  top:140px;
  left:15px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.mini_full-points{
  color:#ffffff;
  width:200px;
  font-size:80px;
  font-weight: bold;
  position:absolute;
  top:140px;
  left:568px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.mini_full-rank{
  color:#ffffff;
  width:278px;
  font-size:50px;
  font-weight: bold;
  font-style: italic;
  position:absolute;
  top:190px;
  left:320px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.mini_full-ecart{
  color:#ffffff;
  font-size:30px;
  position:absolute;
  top:155px;
  left:310px;
  width:200px;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.mini_full-flag{
  position:absolute;
  top:15px;
  left:15px;
  width:120px;
}

.mini_line{
  background-color: white;
  width:700px;
  height:2px;
  top:150px;
  left:30px;
  position:absolute;
}

.mini_time{
  color:#ffffff;
  width:768px;
  font-size:130px;
  font-weight: bold;
  position:absolute;
  top:25px;
  left:0px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}