/* .main{
  background-color: #000000;
} */

p {
  margin-bottom: 0 !important;
}

.speak_title{
  color:white;
  font-size: 50px;
  text-align: center;
  border-bottom: 4px solid white;  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.speak_title_text{
  width:1650px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.speak_fond_impair{
  background-color: #073E60;
}

.speak_fond_pair{
  background-color: #085483;
}


.speak_time-table{
  font-variant-numeric: tabular-nums;
  font-size:40px;
  padding-top:8px;
  text-align: right;
  /* background-color: red; */
}
.speak_title_time{
  margin-right: 20px;
}

.speak_leaders{
  color:#ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.speak_table-results{
  /* background-color: #073E60; */
}
.speak_line-res{
  font-size:30px;
  border-bottom: 2px solid white;
}
.speak_line-res-cav{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:400px;
  height:50px;
}
.speak_line-res-horse{
  font-size:25px;
  width:450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height:40px;
}
.speak_line-small-line{
  margin-top: 5px;
  margin-bottom: 5px;
}
.speak_line-res-detail-judges{
  font-size:20px;
  text-align: center;
}
.speak_line-res-cav-horse{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.speak_line-res-points{
  font-size:25px;
  padding-top:3px;
}


.speak_last-title{
  font-size:20px;
  border-bottom: 2px solid white;
}

.speak_last-cav-horse{
  font-size:30px;
}

.speak_last-horse{
  font-size:25px;
}

.speak_alignCenter{
  text-align: center;
}

.speak_alignRight{
  text-align: right;
  /* direction: rtl; */
}
.speak_alignRight span{
  /* direction: ltr; */
  /* unicode-bidi: bidi-override; */
}
.speak_line-res-pointsFlag{
  padding-top: 5px;
}

.speak_chrono{
  font-variant-numeric: tabular-nums;
}
.speak_small-score{
  font-size:22px;
  padding-top:15px;
}

.speak_rowResults{
  background-color:#073E60;
}
.speak_table-last{
  padding-bottom:15px;
  background-color: #031f30;
}
.speak_table-stats{
  background-color: #031f30;
}
.speak_table-live{
  padding-bottom:15px;
  background-color: #420a0a;
}

.speak_rowResultsLive{
  background-color:#5e0f0f;
  margin-top: unset !important;
  margin-right: unset !important;
  margin-left: unset !important;
}

.speak_rowResultsLast{
  margin-top: unset !important;
  margin-right: unset !important;
  margin-left: unset !important;
}

.speak_stats-title{
  border-bottom: 2px solid white;
  font-size: 25px;
  text-align: center;
}
.speak_stats-core{
  text-align: center;
  font-size: 40px;
}

.speakTeamMember{
  font-size: 20px;
  margin-bottom: 3px;
  margin-top: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.alignCenter{
  text-align: center;
}

.speak_mini_teams{
  background-color:#073E60;
  color:white;
  width:99% !important;
}
.speak_team_detail{
  text-align: center;
  font-size: 30px;
  border:2px white solid;
}

.alogoStatsTitle{
  border-bottom:2px white solid;
  text-align: center;
}
.alogoStatsTable{
  text-align: center;
}
.alogoStatsCell{
  border:2px white solid;
  padding-left:5px;
  padding-right:5px;
}

.speak_countDown{
  background-color: #031f30;
  width:220px;
  text-align: center;
  margin: auto;
  font-size: 45px;
}

.speak_standings-title{
  background-color: #031f30;
  margin-top:10px;
  font-size:20px;
  border-bottom: 2px solid white;
  padding-left: 10px;
}