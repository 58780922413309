.giant_shf-rider{
  width:1400px;
  color:#ffffff;
  font-size:140px;
  font-weight: bold;
  position:absolute;
  top:270px;
  left:450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.giant_shf-pere{
  color:#ffffff;
  /* font-style:italic; */
  width:1500px;
  font-size:80px;
  font-weight: bold;
  position:absolute;
  top:700px;
  left:20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.giant_shf-grandpere{
  color:#ffffff;
  /* font-style:italic; */
  width:1500px;
  font-size:80px;
  font-weight: bold;
  position:absolute;
  top:950px;
  left:20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.giant_shf-mere{
  color:#ffffff;
  /* font-style:italic; */
  width:1500px;
  font-size:80px;
  font-weight: bold;
  position:absolute;
  top:825px;
  left:20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.giant_shf-horse{
  color:#163542;
  width:1880px;
  font-size:150px;
  font-weight: bold;
  position:absolute;
  top:460px;
  left:20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.giant_shf-race{
  color:#163542;
  width:350px;
  font-size:120px;
  font-weight: bold;
  position:absolute;
  top:650px;
  left:1580px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* background-color: red; */
  text-align: center;
}
.giant_shf-num{
  width:350px;
  color:#ffffff;
  font-size:140px;
  font-weight: bold;
  position:absolute;
  top:265px;
  left:0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  /* background-color: red; */
}
.giant_shf-chrono{
  width:571px;
  height: 203px;
  color:#ffffff;
  font-size:100px;
  font-weight: bold;
  position:absolute;
  top:0px;
  left:1349px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.giant_shf-chrono-txt{
  position:relative;
  width:450px;
  height: 203px;
  top:25px;
  left:140px;
  text-align: center;
  /* background-color: red; */
}

.giant_shf-points{
  color:#163542;
  font-size:120px;
  font-weight: bold;
  position:absolute;
  top:0px;
  left:1035px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:352px;
  height:187px;
}

.giant_full-rank{
  color:#ffffff;
  width:650px;
  /* background-color:#011e23; */
  padding-left: 5px;
  padding-right: 5px;
  font-size:100px;
  font-weight: bold;
  position:absolute;
  top:927px;
  left:1200px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.giant_full-ecart{
  color:#ffffff;
  font-size:125px;
  position:absolute;
  top:900px;
  left:275px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  /* height:150px; */
}

.giant_full-ranking-title{
  position:absolute;
  top:35px;
  left:50px;
  width:1860px;
  height:107px;
}
.giant_full-ranking-title-text{
  width:1860px;
  color:#ffffff;
  font-size:100px;
  font-weight: bold;
  position:absolute;
  top:-24px;
  left:0px;
  text-align:center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*.giant_full-ranking-lines{
  position:absolute;
  top:180px;
  left:28px;
  width:1860px;
  height:1000px;
}
.giant_full-ranking-line{
  width:1860px;
  height:180px;
  position:relative;
  margin-bottom:0px;
  /* background-color:red; 
}
.giant_full-ranking-line-rank{
  color:#ffffff;
  position:absolute;
  font-size:75px;
  font-weight: bold;
  top:-10px;
  left:0px;
  text-align:center;
  width:120px;
}
.giant_full-ranking-line-rider{
  color:#ffffff;
  width:1120px;
  position:absolute;
  font-size:80px;
  font-weight: bold;
  top:-14px;
  left:130px;
  text-align:left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.giant_full-team-line-rider{
  color:#ffffff;
  width:1100px;
  position:absolute;
  font-size:80px;
  font-weight: bold;
  top:35px;
  left:50px;
  text-align:left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.giant_full-ranking-line-horse{
  color:#ffffff;
  position:absolute;
  font-size:70px;
  font-weight: bold;
  top:84px;
  left:400px;
  text-align:left;
  width:1400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.giant_full-ranking-line-res{
  color:#ffffff;
  position:absolute;
  font-size:70px;
  font-weight: bold;
  top:-7px;
  left:1450px;
  width:440px;
  text-align:center;
}*/
.giant_full-flag{
  position:absolute;
  top:45px;
  left:1610px;
  width:200px;
}

/* .giant_full-ranking-flag{
  position:absolute;
  top:19px;
  left:1250px;
  text-align:center;
} */
/*
.giant_full-team-title{
  position:absolute;
  top:35px;
  left:50px;
  width:1860px;
  height:107px;
}
.giant_full-team-title-text{
  width:1860px;
  color:#ffffff;
  font-size:100px;
  font-weight: bold;
  position:absolute;
  top:-30px;
  left:20px;
  text-align:left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.giant_full-team-flag{
  position:absolute;
  top:45px;
  left:1610px;
  width:200px;
}

.giant_full-team-total{
  position:absolute;
  top:940px;
  left:150px;
  width:1860px;
  height:107px;
}
.giant_full-team-total-text{
  width:1860px;
  color:#ffffff;
  font-size:120px;
  font-weight: bold;
  position:absolute;
  top:-40px;
  left:-100px;
  text-align:left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.giant_full-team-points-text{
  width:1860px;
  color:#ffffff;
  font-size:120px;
  font-weight: bold;
  position:absolute;
  top:-40px;
  left:300px;
  text-align:left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.giant_full-team-lines{
  position:absolute;
  top:172px;
  left:28px;
  width:1860px;
  height:1000px; 
}

.giant_full-team-line{
  width:1860px;
  height:162px;
  position:relative;
  margin-bottom:20px;
}

.giant_full-team-line-round1{
  color:#ffffff;
  position:absolute;
  font-size:120px;
  font-weight: bold;
  top:0px;
  left:1100px;
  text-align:center;
  width:400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
*/
/* .giant_full-team-line-round2{
  color:#011537;
  position:absolute;
  font-size:120px;
  font-weight: bold;
  top:0px;
  left:1500px;
  text-align:center;
  width:400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

} */
/*
.giant_full-team-champ-line-round1{
  color:#ffffff;
  position:absolute;
  font-size:120px;
  font-weight: bold;
  top:0px;
  left:910px;
  text-align:center;
  width:500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.giant_full-team-champ-line-round2{
  color:#ffffff;
  position:absolute;
  font-size:120px;
  font-weight: bold;
  top:0px;
  left:1300px;
  text-align:center;
  width:400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.giant_full-team-champ-line-round3{
  color:#ffffff;
  position:absolute;
  font-size:120px;
  font-weight: bold;
  top:0px;
  left:1550px;
  text-align:center;
  width:400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.giant_full-team-rank-text{
  width:1860px;
  color:#ffffff;
  font-size:120px;
  font-weight: bold;
  position:absolute;
  top:-40px;
  left:1200px;
  text-align:left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}*/

.giant_full_line1{
  width:1920px;
  border-top:6px #6db757 solid;
  position:absolute;
  top:295px;
  left:0px;

}
.giant_full_line2{
  width:1920px;
  border-top:6px #6db757 solid;
  position:absolute;
  top:540px;
  left:0px;

}
